import React from 'react'
import ReactDOM from 'react-dom'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import image from 'url:../img/symbol/svg/sprite.symbol.svg'

function TableExample(props) {
	return (
		<Table>
			<Thead>
				<Tr>
					<Th colSpan="2" className="table__name table__name--visible">
						<div className="table__city">
							<span>Москва</span>
							<span className="table__icon">
								<svg>
									<use href={image + '#right-arrow-of-straight-thin-line'} />
								</svg>
							</span>
							<span>Санкт-Петербург</span>
						</div>
						<p className="table__size">3 кг, габариты 25*25*20 см</p>
					</Th>
					<Th className="table__shadow--right">
						<strong>Без договора</strong>
						<p>Тариф «Экспресс лайт»</p>
					</Th>
					<Th className="table__best">
						<strong>С договором</strong>
						<p>
							Тариф «Посылка»
							<br />
							для интернет магазина
						</p>
					</Th>
					<Th className="table__economy table__shadow">
						<p>
							<strong>Экономия</strong>
						</p>
					</Th>
				</Tr>
			</Thead>
			<Tbody>
				<Tr colSpan="2">
					<Td rowSpan="4">
						<p>Срок доставки 1 - 2 дня</p>
					</Td>
				</Tr>
				<Tr>
					<Td>
						<p>
							<span>дверь</span>
							<span className="table__icon">
								<svg>
									<use href={image + '#right-arrow-of-straight-thin-line'} />
								</svg>
							</span>
							<span>дверь</span>
						</p>
					</Td>
					<Td className="table__shadow--right">
						<p>
							<span>
								750 <i>₽</i>
							</span>
						</p>
					</Td>
					<Td className="">
						<p>
							<span>
								450 <i>₽</i>
							</span>
						</p>
					</Td>
					<Td className="table__shadow">
						<p>
							<span>
								40 <i>%</i>
							</span>
						</p>
					</Td>
				</Tr>
				<Tr>
					<Td className="table__middle">
						<p>
							<span>склад</span>
							<span className="table__icon">
								<svg>
									<use href={image + '#right-arrow-of-straight-thin-line'} />
								</svg>
							</span>
							<span>дверь</span>
						</p>
						<p>
							<span>дверь</span>
							<span className="table__icon">
								<svg>
									<use href={image + '#right-arrow-of-straight-thin-line'} />
								</svg>
							</span>
							<span>склад</span>
						</p>
					</Td>
					<Td className="table__middle table__shadow--right">
						<p>
							<span>
								650 <i>₽</i>
							</span>
						</p>
					</Td>
					<Td className="table__middle">
						<p>
							<span>
								310 <i>₽</i>
							</span>
						</p>
					</Td>
					<Td className="table__middle table__shadow">
						<p>
							<span>
								209 <i>%</i>
							</span>
						</p>
					</Td>
				</Tr>
				<Tr>
					<Td>
						<p>
							<span>склад</span>
							<span className="table__icon">
								<svg>
									<use href={image + '#right-arrow-of-straight-thin-line'} />
								</svg>
							</span>
							<span>дверь</span>
						</p>
					</Td>
					<Td className="table__shadow--right">
						<p>
							<span>
								570 <i>₽</i>
							</span>
						</p>
					</Td>
					<Td>
						<p>
							<span>
								170 <i>₽</i>
							</span>
						</p>
					</Td>
					<Td className="table__shadow">
						<p>
							<span>
								335 <i>%</i>
							</span>
						</p>
					</Td>
				</Tr>
			</Tbody>
		</Table>
	)
}

ReactDOM.render(<TableExample />, document.querySelector('#table'))
