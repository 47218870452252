document.querySelectorAll('.video').forEach((item) => {
	item.addEventListener('click', (e) => {
		playMedia(event.target)
	})
})

function playMedia(media) {
	if (media.paused && !media.controls) {
		media.play()
		media.setAttribute('controls', true)
	}
}
