import { addBackToTop } from 'vanilla-back-to-top'
addBackToTop({
	diameter: 48,
	backgroundColor: '#069843',
	textColor: '#fff',
})

let mobileMenuButton = document.querySelector('.hamburger')
let nav = document.querySelector('#nav-container')

mobileMenuButton.addEventListener('click', (event) => {
	event.preventDefault()
	if (event.target.classList.contains('hamburger-box')) {
		event.target.parentNode.classList.toggle('is-active')
		document.body.classList.toggle('non-scrollable')
		nav.classList.toggle('nav--open')
		return
	} else if (event.target.classList.contains('hamburger-inner')) {
		event.target.parentNode.parentNode.classList.toggle('is-active')
		document.body.classList.toggle('non-scrollable')
		nav.classList.toggle('nav--open')
		return
	}
	event.target.classList.toggle('is-active')
	document.body.classList.toggle('non-scrollable')
	nav.classList.toggle('nav--open')
})

let menuLinks = document.querySelectorAll('.nav__link')

menuLinks.forEach((item) => {
	item.addEventListener('click', (e, i) => {
		e.preventDefault()
		if (nav.classList.contains('nav--open') && document.body.classList.contains('non-scrollable')) {
			mobileMenuButton.classList.toggle('is-active')
			nav.classList.toggle('nav--open')
			document.body.classList.toggle('non-scrollable')
		}
		let linkID = document.querySelector(`${e.target.getAttribute('href')}`)
		const gapFromBlockId = 200
		let top = 0
		if (linkID) {
			top = linkID.offsetTop - gapFromBlockId
			window.scrollTo({
				top,
				left: 0,
				behavior: 'smooth',
			})
		}
	})
})
