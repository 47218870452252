import Tingle from 'tingle.js'
import '../../node_modules/tingle.js/src/tingle.css'
import '../scss/modal.scss'
import axios from 'axios'

let buttonsModal = document.querySelectorAll('[data-modal]')
let formsModal = document.querySelectorAll('form')
let buttonDownload = document.querySelector('#download')

let modalForm = new Tingle.modal({
	footer: false,
	stickyFooter: false,
	closeMethods: ['overlay', 'button', 'escape'],
	closeLabel: '',
	cssClass: ['custom-class-1', 'custom-class-2'],
	onOpen: function () {
		modalForm.modal.querySelector('form').addEventListener('submit', onSubmit)
	},
	onClose: function () {},
	beforeClose: function () {
		// here's goes some logic
		// e.g. save content before closing the modal
		return true // close the modal
		return false // nothing happens
	},
})

let modalFinish = new Tingle.modal({
	footer: false,
	stickyFooter: false,
	closeMethods: ['overlay', 'button', 'escape'],
	closeLabel: '',
	cssClass: ['custom-class-1', 'custom-class-2'],
	onOpen: function () {},
	onClose: function () {},
	beforeClose: function () {
		// here's goes some logic
		// e.g. save content before closing the modal
		return true // close the modal
		return false // nothing happens
	},
})

let modalFinishDownload = new Tingle.modal({
	footer: false,
	stickyFooter: false,
	closeMethods: ['overlay', 'button', 'escape'],
	closeLabel: '',
	cssClass: ['custom-class-1', 'custom-class-2'],
	onOpen: function () {},
	onClose: function () {},
	beforeClose: function () {
		// here's goes some logic
		// e.g. save content before closing the modal
		return true // close the modal
		return false // nothing happens
	},
})

let modalDownload = new Tingle.modal({
	footer: false,
	stickyFooter: false,
	closeMethods: ['overlay', 'button', 'escape'],
	closeLabel: '',
	cssClass: ['custom-class-1', 'custom-class-2'],
	onOpen: function () {
		modalDownload.modal.querySelector('form').addEventListener('submit', onSubmit)
	},
	onClose: function () {},
	beforeClose: function () {
		// here's goes some logic
		// e.g. save content before closing the modal
		return true // close the modal
		return false // nothing happens
	},
})

modalFinish.setContent(`
<div class="modal">
  <h1 class="title modal__title">Спасибо!
	<span>Ваша заявка принята.<br><br> В ближайшее время я свяжусь с Вами.</span></h1>
</div>
`)

modalFinishDownload.setContent(`
<div class="modal">
  <h1 class="title modal__title">Спасибо!
	<span>Ваша заявка принята.<br><br> В течение 5 минут вы получите тариф «Посылка» на указанный Email.</span></h1>
</div>
`)

modalForm.setContent(`
<form class="form">
	<p class="form__text">
		<span>Получите консультацию</span>
		<span>Конкретно для Вашего бизнеса</span>
		<span>Оставьте свой номер телефона и менеджер свяжется с Вами в течение 24 часов для консультации</span>
	</p>
	<input type="hidden" name="form_id" value="4">
	<input type="text" required name="phone" placeholder="8 800 000 00 00">
	<button class="btn" type="submit">Получить консультацию</button>
	<p class="form__text">
		<span>Это бесплатно и ни к чему не обязывает</span>
	</p>
</form>
`)

modalDownload.setContent(`
<section class="questions questions--bg questions--modal">
<div class="questions__container wrapper">
	<div class="questions__wrapper">
		<strong class="questions__title">Получите специальный тариф "Посылка"<br> для интернет-магазинов</strong>
		<p class="questions__subtitle">Введите адрес электронной почты и через 5 минут получите тарифы</p>
		<form class="questions__form" id="questions-form">
			<input type="hidden" name="form_id" value="5">
			<input type="email" required name="email" id="email" placeholder="Ваша электронная почта">
			<button class="btn btn--center" type="submit">Получить тариф</button>
		</form>
	</div>
</div>
</section>
`)

buttonsModal.forEach((item) => {
	item.addEventListener('click', (e) => {
		modalForm.open()
	})
})

buttonDownload.addEventListener('click', (e) => {
	modalDownload.open()
})

// Parse the URL
function getParameterByName(name) {
	name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
	let regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
		results = regex.exec(location.search)
	return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}
// Give the URL parameters variable names
let source = getParameterByName('utm_source')
let medium = getParameterByName('utm_medium')
let campaign = getParameterByName('utm_campaign')
let term = getParameterByName('utm_term')
let content = getParameterByName('utm_content')

let utms = [
	['utm_source', source],
	['utm_medium', medium],
	['utm_campaign', campaign],
	['utm_term', term],
	['utm_content', content],
]

let onSubmit = function (event) {
	event.preventDefault()

	utms.forEach((item) => {
		if (item[1]) {
			let input = document.createElement('input')
			input.setAttribute('type', 'hidden')
			input.setAttribute('name', item[0])
			input.setAttribute('value', item[1])
			if (!event.target.querySelector(`[name="${item[0]}"]`)) {
				event.target.appendChild(input)
			}
		}
	})

	let data = {}

	this.querySelectorAll('[name]').forEach((element) => {
		data[element.name] = element.value
	})

	// let data = Object.entries(event.target.elements)
	// .forEach((item) => {
	// 	// console.log(item[1]['name'], item[1]['value'])
	// })

	axios.post('/mail', data)
	event.target.reset()
	modalForm.close()
	modalDownload.close()
	let checkIdForm = event.target.querySelector(`[name="form_id"]`)
	if (checkIdForm.value == 2 || checkIdForm.value == 5) {
		modalFinishDownload.open()
	} else {
		modalFinish.open()
	}
	setTimeout(() => {
		modalFinish.close()
		modalFinishDownload.close()
	}, 4000)
}

formsModal.forEach((item) => {
	item.addEventListener('submit', onSubmit)
})
