import IMask from 'imask'

document.querySelectorAll('input[name="phone"]').forEach((item) => {
	let phoneMask = IMask(item, {
		mask: '{8} 000 000 00 00',
	})
})

let emailMask = new IMask(document.querySelector('input[name="email"]'), {
	mask: function (value) {
		if (/^[a-z0-9_\.-]+$/.test(value)) return true
		if (/^[a-z0-9_\.-]+@$/.test(value)) return true
		if (/^[a-z0-9_\.-]+@[a-z0-9-]+$/.test(value)) return true
		if (/^[a-z0-9_\.-]+@[a-z0-9-]+\.$/.test(value)) return true
		if (/^[a-z0-9_\.-]+@[a-z0-9-]+\.[a-z]{1,4}$/.test(value)) return true
		if (/^[a-z0-9_\.-]+@[a-z0-9-]+\.[a-z]{1,4}\.$/.test(value)) return true
		if (/^[a-z0-9_\.-]+@[a-z0-9-]+\.[a-z]{1,4}\.[a-z]{1,4}$/.test(value)) return true
		return false
	},
})
